.myaccordion {
    input:checked ~ .myaccordion-body {
        max-height: 100rem;
    }
}

.border-menu {
    background: #f1f1fc ;
    color: #5755d9;
}

.myaccordion-body {
    margin-bottom: .4rem;
    max-height: 0;
    overflow: hidden;
    transition: max-height .25s;
}