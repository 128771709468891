

.timeStep {
    padding-right: 17px;
    padding-left: 3px;
    padding-bottom: 2px;
    border-bottom: 3px dashed #aaa;
    margin-bottom: 5px;
    margin-left: 3px;
}

.timeStep:first-child::before {
    content: '◀';
    position: relative;
    color: #aaa;
    top: 23px;
    left: -6px;
}

.titreTimeline {
    margin-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: -2px;
    cursor: hand;
    color: #5755d9;
}

.noteQuestion {
    border-top: 1px lightgray solid;
    padding-top: 3px;
    padding-bottom: 3px;
}