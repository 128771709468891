.question {
  margin-bottom: 5px;
  padding-bottom: 5px;
  background-color: #fafafa;
}

.headerColumn {
  background-color: #fafafa;
}
.headerNumero {
  width: 60px;
}
.headerQuestion {
  color: gray;
  font-size: 10px;
}

.titreModif {
  color: rgb(4, 116, 185);
}

.titreDevoir {
  color: gray;
}

.dragElement {
  appearance: none;
  width: 60px;
  border: 0.05rem solid #5755d9;
  border-radius: 0.1rem;
  color: #5755d9;
  height: 1.8rem;
  outline: none;
  display: inline-block;
  padding: calc((1.8rem - 1.2rem) / 2 - 0.05rem) 0.4rem;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 1.2rem;
  font-size: 14px;
  cursor: move;
}

.numeroQ {
  font-size: 12px;
  margin: 2px 2px 2px 2px;
}
