.eleve {
  margin-top: 10px;
  padding-left: 5px;
  margin-right: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid gray;
}

.chapitreContainer {
  padding: 1px 0px 0px 0px;
  height: 35px;
  overflow-x: auto;
}

.chapitre {
  margin: 1px 2px 0px 0px;
  font-size: 10px;
  color: gray;
  padding: 0px 5px 0px 5px;
  border: 1px solid gray;
  border-radius: 0px 5px 0px 5px;
}

.longButton {
  width: 100%;
}

.numero {
  appearance: none;
  border: 0.05rem solid #5755d9;
  border-radius: 0.1rem;
  color: #5755d9;
  height: 1.8rem;
  outline: none;
  display: inline-block;
  padding: calc((1.8rem - 1.2rem) / 2 - 0.05rem) 0.4rem;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 1.2rem;
  font-size: 14px;
}