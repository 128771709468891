.app {
  margin-top: 20px;
  min-height: calc(100vh - 90px);
}

@media (max-width: 600px) {
  .app {
    margin-top: 0px;
    min-height: calc(100vh - 90px);
  }
}

.w3-center{text-align:center!important}
.defi {
  color :  #d43e20;
  font-weight: 500;
  /* -webkit-text-stroke: 0.5px black; */
}

.noteItem {
  text-align: center;
  margin-left: 5px;
  padding: 5px;
  cursor: pointer;
}
.noteItem:hover {
  background-color: #0000001c;
}
.noteItem:active {
  background-color: #3f84ec70;
}
.noteItem:actived {
  background-color: #3f84ec70;
}
.noteItemActive {
  text-align: center;
  margin-left: 5px;
  padding: 5px;
  cursor: pointer;
  background-color: #3f84ec70;
}